import React from 'react'

export default (props) => (
    <svg {...props} width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="10" cy="10" r="10" fill="#0F2B2C"/>
        <circle cx="10" cy="10" r="2" fill="#F8F8EF"/>
        <circle cx="15" cy="10" r="2" fill="#F8F8EF"/>
        <circle cx="5" cy="10" r="2" fill="#F8F8EF"/>
    </svg>

)