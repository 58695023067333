import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LinkWithUTM as Link} from './LinkWithUTM'
import HamburgerButton from "./icons/HamburgerButton";
import EdynCareLogo from '../components/icons/EdynCare'
import CallIcon from '../components/icons/CallIcon'
import HeaderProfile from "./icons/HeaderProfile";

/* global _paq */

export default function Header() {
    const { site: { siteMetadata: {
        appConfig: { domain: appDomain },
        featureToggles: {
            enableBlogContent
        }
    } } } = useStaticQuery(graphql`
        query HeaderQuery {
            site {
                siteMetadata {
                    appConfig {
                        domain
                    }
                    featureToggles {
                        enableBlogContent
                    }
                }
            }
        }
`)
    const navbarSelectionStates = {
        HOW_EDYN_WORKS: "HOW_EDYN_WORKS",
        CONDITIONS: "CONDITIONS",
        LOCATIONS: "LOCATIONS",
        PLANNING_AND_ADVICE: "PLANNING_AND_ADVICE",
        CLOSED: 'CLOSED',
        OPEN: 'OPEN'
    }

    const [selectedField, setSelectedField] = useState(navbarSelectionStates.CLOSED);

    const setNavbarState = (newState) => {
        if (newState === selectedField) return;
        setSelectedField(newState)

        if (typeof _paq !== 'undefined') {
            _paq && _paq.push(['trackEvent', 'Navbar', 'expand', newState]);
        }
    }

    useEffect(() => {
        const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)

        if (vw >= 1152) {
            // same as macbook breakpoint
            return;
        }
        if (selectedField === navbarSelectionStates.CLOSED) {
            const scrollY = document.body.style.top;
            document.body.style.position = '';
            document.body.style.top = '';
            window.scrollTo(0, parseInt(scrollY || '0') * -1);
        } else {
            document.body.style.position = 'fixed';
            document.body.style.top = `-${window.scrollY}px`;
        }
    }, [selectedField])

    const isExpanded = selectedField !== navbarSelectionStates.CLOSED

    return (
        <>
            <nav className={`header ${isExpanded ? "expanded" : ""}`} onMouseLeave={() => setNavbarState(navbarSelectionStates.CLOSED)}>
                <div className="header__visible">
                    <div className="header__button--mobile">
                        <HamburgerButton expanded={isExpanded} onClick={() => setNavbarState(isExpanded ? navbarSelectionStates.CLOSED : navbarSelectionStates.OPEN)}/>
                    </div>
                    <Link className="header__logo-link" to="/">
                        <EdynCareLogo className="header__logo"/>
                    </Link>
                    <a href={`${appDomain}/login-redirect`} className="header__button--mobile">
                        <HeaderProfile />
                    </a>
                    <div className="header__links">
                        <div className="header__routes">
                            <Link to="/liveincare/">Live-in care</Link>
                            <div className="header__dropdown-link" 
                                onMouseOver={() => setNavbarState(navbarSelectionStates.HOW_EDYN_WORKS)}
                            >
                                How Edyn works<ChevronDown className="header__route-chevron"/>
                            </div>
                            <div className="header__dropdown-link" onMouseOver={() => setNavbarState(navbarSelectionStates.CONDITIONS)}>
                                Conditions we care for<ChevronDown className="header__route-chevron"/>
                            </div>
                            <div className="header__dropdown-link" onMouseOver = {() => setNavbarState(navbarSelectionStates.LOCATIONS)}>
                                Locations<ChevronDown className="header__route-chevron"/>
                            </div>
                            { enableBlogContent && 
                                <div className="header__dropdown-link" onMouseOver = {() => setNavbarState(navbarSelectionStates.PLANNING_AND_ADVICE)}>
                                    Planning and advice<ChevronDown className="header__route-chevron"/>
                                </div>
                            }
                        </div>
                        <div className="header__buttons">
                            <div className="header__phone">
                                <CallIcon /><a href="tel:020 3970 9900">020 3970 9900</a> 
                            </div>
                            <Link to="/become-a-carer/">
                                <button className="btn-blue">Become a carer</button>
                            </Link>
                            <a href={`${appDomain}/login-redirect`}>
                                <button className="btn-primary">Login</button>
                            </a>
                        </div>
                
                    </div>
                </div>
                <div className={`header__menu ${isExpanded ? "expanded" : ""}`}>
                    <Link className="header__menu-link--mobile" to="/">Home</Link>
                    <Link className="header__menu-link--mobile" to="/liveincare/">Live-in care</Link>
                    <div className="header__menu-link--mobile">
                        <ChevronRight className="header__menu-chevron-right"/>
                        How Edyn works
                    </div>
                    <div className={`header__menu-element header__menu-how-edyn-works ${selectedField === navbarSelectionStates.HOW_EDYN_WORKS ? "visible" : "" }`}>
                        <div className="header__submenu">
                            <div className="header__submenu-element">
                                <Link to="/approach/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Our approach
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/pricing/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Our pricing
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/our-carers/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Our carers
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/our-team/" target="__blank">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Our team
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <a href="https://www.trustpilot.com/review/edyn.care" target="__blank">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Customer reviews
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="header__menu-link--mobile">
                        <ChevronRight className="header__menu-chevron-right"/>
                        Conditions we care for
                    </div>
                    <div className={`header__menu-element header__menu-conditions ${selectedField === navbarSelectionStates.CONDITIONS ? "visible" : "" }`}>
                        <div className="header__submenu">
                            <div className="header__submenu-element">
                                <Link to="/condition/dementia/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Dementia
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/condition/parkinsons/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Parkinson's
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/condition/cancer/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Cancer
                                </Link>
                            </div>
                        </div>
                        <div className="header__submenu">
                            <div className="header__submenu-element">
                                <Link to="/condition/stroke/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Stroke
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/condition/multiple-sclerosis/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    Multiple Sclerosis
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="header__menu-link--mobile">
                        <ChevronRight className="header__menu-chevron-right"/>
                        Locations
                    </div>
                    <div className={`header__menu-element header__menu-locations ${selectedField === navbarSelectionStates.LOCATIONS ? "visible" : "" }`}>
                        <div className="header__submenu">
                            <div className="header__submenu-element">
                                <Link to="/location/london/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    London
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/location/east-midlands/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    East Midlands
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to= "/location/west-of-england/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    West Of England
                                </Link>
                            </div>
                        </div>
                        <div className="header__submenu">
                            <div className="header__submenu-element">
                                <Link to="/location/south-east/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    South East
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/location/south-west/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    South West
                                </Link>
                            </div>
                            <div className="header__submenu-element">
                                <Link to="/location/west-midlands/">
                                    <ChevronRight className="header__menu-chevron-right"/>
                                    West Midlands
                                </Link>
                            </div>
                        </div>
                    </div>
                    { enableBlogContent &&
                        <> 
                            <div className="header__menu-link--mobile">
                            <ChevronRight className="header__menu-chevron-right"/>
                                Planning & advice
                            </div>
                            <div className={`header__menu-element header__menu-planning-and-advice ${selectedField === navbarSelectionStates.PLANNING_AND_ADVICE ? "visible" : "" }`}>
                                <div className="header__submenu">
                                    <div className="header__submenu-element">
                                        <ChevronRight className="header__menu-chevron-right"/>
                                        Starting the conversation
                                    </div>
                                    <div className="header__secondary-submenu">
                                        <Link to="/blog/when-is-care-needed/">When is care needed?</Link>
                                        <Link to="/blog/what-can-a-live-in-carer-help-with/">What can a live-in carer help with?</Link>
                                        <Link to="/blog/live-in-care-vs-care-home/">Live-in care vs a care home</Link>
                                        <Link to="/blog/discussing-care-options-as-a-family/">Discussing care options as a family</Link>
                                    </div>
                                </div>
                                <div className="header__submenu">
                                    <div className="header__submenu-element">
                                        <ChevronRight className="header__menu-chevron-right"/>
                                        Preparing for care
                                    </div>
                                    <div className="header__secondary-submenu">
                                        <Link to="/blog/how-to-decide-on-the-right-carer-for-you/">How to decide on the right carer for you</Link>
                                        <Link to="/blog/what-is-a-care-assessment/">What is a care assessment?</Link>
                                        <Link to="/blog/cqc-vs-introductory-agency/">CQC vs introductory agency</Link>
                                        <Link to="/blog/what-is-a-care-consultation/">What is a care consultation?</Link>
                                    </div>
                                </div>
                                <div className="header__submenu">
                                    <div className="header__submenu-element">
                                        <ChevronRight className="header__menu-chevron-right"/>
                                        Paying for care
                                    </div>
                                    <div className="header__secondary-submenu">
                                        <Link to="/blog/state-and-local-authority-funding/">State and local authority funding</Link>
                                        <Link to="/blog/self-funding-care/">Self-funding care</Link>
                                        <Link to="/blog/what-is-the-cost-of-live-in-care/">What is the cost of live-in care?</Link>
                                        <Link to="/blog/what-are-my-funding-options/">What are my funding options?</Link>
                                    </div>
                                </div>
                                <div className="header__submenu">
                                    <div className="header__submenu-element">
                                        <ChevronRight className="header__menu-chevron-right"/>
                                        Covid-19
                                    </div>
                                    <div className="header__secondary-submenu">
                                        <Link to="/blog/our-covid-19-response">Our Covid-19 response</Link>
                                        <a href="https://docs.google.com/document/d/e/2PACX-1vSzByDSvH8O59HdWd5llXGWBuzpepko0dKADvDgEpDtRqRpAH4x6AMUKdad8C9WvnaSQvhnsd_KOS7_/pub">Covid-19 policy for our clients</a>
                                        <a href="https://docs.google.com/document/d/e/2PACX-1vRMZjqqNCoTJpfefQ1KpEGNrI8scwGUpeIn2IJM35MH4w9_zjp_iHHGXmnJN3oRVxUpgQ38PXtaJVqc/pub">Covid-19 testing policy</a>
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    <div className="header__menu-buttons--mobile">
                        <div>
                            <a href={`${appDomain}/login-redirect`}>
                                <button className="btn-primary">Login</button>
                            </a>
                            <Link to="/cta/">
                                <button className="btn-green">Arrange a call</button>
                            </Link>
                        </div>
                        <Link to="/become-a-carer/">
                            <button className="btn-transparent">Become a carer</button>
                        </Link>
                    </div>
                </div>
            </nav>
            <div className={`header__vertical-spacer ${selectedField === navbarSelectionStates.CLOSED ? 'header__vertical-spacer--hide' : 'header__vertical-spacer--show'}`} />
        </>
    );
}



const ChevronDown = props => 
    <svg {...props} width="8" height="5" viewBox="0 0 8 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.82873 1L4.0003 3.82843L1.17188 1" stroke="#1C4042"/>
    </svg>

const ChevronRight = props =>
    <svg {...props}  width="7" height="8" viewBox="0 0 7 8" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.82812 0.999884L5.65655 3.82831L2.82812 6.65674" stroke="#1C4042"/>
    </svg>
