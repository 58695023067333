import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { LinkWithUTM as Link } from './LinkWithUTM';
import { StaticImage } from "gatsby-plugin-image";

import LiveChatIcon from '../components/icons/Footer/LiveChatIcon'
import PhoneIcon from '../components/icons/Footer/PhoneIcon'
import PhoneIconCard from './icons/Footer/PhoneIconCard';
import EmailIcon from "./icons/Footer/EmailIcon";
import Twitter from './icons/Footer/Twitter';
import Facebook from './icons/Footer/Facebook';
import EdynCareWhite from './icons/EdynCareWhite';
import { Video } from 'gatsby-video';

const isBrowser = typeof window !== "undefined";

const Footer = ({includeCTA = true, includeBecomeACarer = false}) => {
    const componentData = useStaticQuery(graphql`
    query FooterQuery {
        site {
            siteMetadata {
                auth0Config {
                    auth0Domain
                    auth0ClientId
                    auth0CallbackUrl
                }
                appConfig {
                    domain
                }
                featureToggles {
                    enableBlogContent
					enableExtendedBlogContent
                    enableLocationContent
                    enableConditionsContent
                    enableLiveChat
                }
            }
        }
		footerVideo: file(relativePath: {eq: "footer-video.mov"}) {
			childVideoFfmpeg {
				webm: transcode(
					outputOptions: ["-crf 31", "-b:v 0"]
					maxWidth: 900
					maxHeight: 480
					fileExtension: "webm"
					codec: "libvpx-vp9"
				){
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				}
				mp4: transcode(
					maxWidth: 900
					maxHeight: 480
					fileExtension: "mp4"
					codec: "libx264"
				){
					width
					src
					presentationMaxWidth
					presentationMaxHeight
					originalName
					height
					fileExtension
					aspectRatio
				}
			}
		}
    }
`)

    const { 
		site: { 
			siteMetadata: {
				appConfig: { domain: appDomain },
				featureToggles: {
					enableBlogContent,
					enableExtendedBlogContent,
					enableLocationContent,
					enableConditionsContent,
					enableLiveChat
				}
    		}
 		},
		footerVideo
		 
	} = componentData
	const footerVideoFiles = footerVideo.childVideoFfmpeg;

	return (
		<footer>
			<div className={`footer-container ${!includeCTA ? "cta-disable" : ""}`}>
				{includeCTA && (
					<div className="family-advisor">
						<div className="left-block">
							<h1>
								Talk to a Family <br />
								Care Advisor
							</h1>
							<p className="copy">
								Whether you’re new to Edyn or a long-time client, you’ll get
								compassionate and invaluable support from our expert team.
							</p>
							<div className="cta-contact-wrapper">
								<ul>
									{enableLiveChat && (
										<li>
											<LiveChatIcon className="icon-default"/>
											<p>
												<div onClick={() => {
													if (isBrowser) return window.Intercom('show');
												}} className="underline links">
													Live chat
												</div>
												<span> Speak with a care expert within 60 seconds</span>
											</p>
										</li>
									)}
									<li className="arrange-call-back">
										<div className="arrange-phone">
											<PhoneIconCard className="icon-default"/>
											<p>
												<a href="tel:020-3970-9900" className="links">
													020 3970 9900
												</a>
												<span> We’re open 9am-5pm, Monday-Friday</span>
											</p>
										</div>
										<div className="cta">
											<Link to="/cta/">
												<button className="btn-orange">Or arrange a call back</button>
											</Link>
										</div>
									</li>
								</ul>
								
							</div>
						</div>
						<div className="right-block">
							<StaticImage placeholder="blurred"
								className="tablet"
								src="../../static/assets/marketing-site/images/family-advisor.png"
								objectFit='contain'
							/>
							<StaticImage placeholder="blurred"
								className="mobile"
								src="../../static/assets/marketing-site/images/family-advisor-small.png"
								objectFit='contain'
							/>
						</div>
					</div>
				)}
				<div className="footer-background">
					<div className="footer-video-wraper">
						<Video 
							sources={[footerVideoFiles.webm, footerVideoFiles.mp4]}
							autoPlay
							loop
							playsInline
							muted
						/>
					</div>
					<div className={`footer-block mobile ${!includeCTA ? "no-cta" : ""}`}>
						<div className="footer-logo">
							<Link to="/">
								<EdynCareWhite />
							</Link>
						</div>
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="/">Home</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/liveincare/">Live-in Care</Link>
									</li>
									{/* <li className="footer-nav-title link">
										<Link to="">Our Service</Link>
									</li> */}
									<li className="footer-nav-title link">
										<Link to="/approach/">Our Approach</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/pricing/">Our Pricing</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/our-carers/">Our Carers</Link>
									</li>
									<li className="footer-nav-title link">
										<a target='__blank' href="https://apply.workable.com/edyn-care">Jobs</a>
									</li>
									<li className="footer-nav-title link">
										<Link to="/our-team/">Our Team</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/blog/">Planning & Advice</Link>
									</li>
									<li className="footer-nav-title link">
										<a href="https://uk.trustpilot.com/review/edyn.care" target="_blank">Customer reviews</a>
									</li>
									{/*   About accordion  */}
                                    {/* {enableBlogContent && (
                                        <li className="footer-nav-title link accordion">
                                            <Link to="#" className="accordion-link">
                                                <StaticImage placeholder="blurred"
                                                    src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
                                                    alt=""
                                                    objectFit="contain"
                                                    />
                                                <span>About</span>
                                            </Link>
                                            <div
                                                className="snd-sub-menu accordion-items-container"
                                                data-collapsed="true"
                                            >
                                                <ul>
                                                    <li>
                                                        <Link to="#">Why we started</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Our values</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Our team</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="#">Our advisors</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )} */}
									{/*   Conditions We Care for accordion  */}
                                    {enableConditionsContent && (
                                        <li className="footer-nav-title">
                                            {/* <Link to="#" className="accordion-link"> */}
                                                <span>Conditions we care for</span>
                                            {/* </Link> */}
    
                                            <div
                                                className="snd-sub-menu accordion-items-container"
                                            >
                                                <ul>
                                                    <li className="footer-sub">
                                                        <Link to="/condition/dementia/">Dementia</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/condition/parkinsons/">Parkinson's</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/condition/cancer/">Cancer</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/condition/stroke/">Stroke</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/condition/multiple-sclerosis/">Multiple Sclerosis</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )}
                                    {enableLocationContent && (
                                        <li className="footer-nav-title">
                                            {/* <Link to="#" className="accordion-link"> */}
                                                <span>Locations</span>
                                            {/* </Link> */}

                                            <div
                                                className="snd-sub-menu accordion-items-container"
                                            >
                                                <ul>
                                                    <li className="footer-sub">
                                                        <Link to="/location/london/">London</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/location/east-midlands/">East Midlands</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/location/west-of-england/">West Of England</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/location/south-east/">South East</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/location/south-west/">South West</Link>
                                                    </li>
                                                    <li className="footer-sub">
                                                        <Link to="/location/west-midlands/">West Midlands</Link>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    )}

									
									{/*   Training & Support Accordion  */}
									<li className="footer-nav-title link accordion">
                                        {enableExtendedBlogContent && (
                                            <Link to="#" className="accordion-link">
                                                <StaticImage placeholder="blurred"
                                                    src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
                                                    alt=""
                                                    objectFit="contain"
                                                />
                                                <span>Training & Support</span>
                                            </Link>
                                        
                                        )}

										<div
											className="snd-sub-menu accordion-items-container"
											data-collapsed="true"
										>
											<ul>
												<li className="footer-sub">
													<Link to="#">Our training process</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Ongoing support</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Refer-a-friend scheme</Link>
												</li>
												<li className="footer-sub">
													<Link to="#">Apply now</Link>
												</li>
											</ul>
										</div>
									</li>
									{/*   Client Stories Accordion  */}
									{enableExtendedBlogContent && (
                                        <>

                                            <li className="footer-nav-title link accordion">
                                                <Link to="#" className="accordion-link">
                                                    <StaticImage placeholder="blurred"
                                                        src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
                                                        alt=""
                                                        objectFit="contain"
        
                                                    />
                                                    <span>Client stories</span>
                                                </Link>
                                                <div
                                                    className="snd-sub-menu accordion-items-container"
                                                    data-collapsed="true"
                                                >
                                                    <ul>
                                                        <li className="footer-sub">
                                                            <Link to="#">Matthew's story</Link>
                                                        </li>
                                                        <li className="footer-sub">
                                                            <Link to="#">Iwona &amp; Neil's story</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                            {/*   Planning & Advice Accordion  */}
                                            <li className="footer-nav-title link accordion">
                                                <Link to="#" className="accordion-link">
                                                    <StaticImage placeholder="blurred"
                                                        src="../../static/assets/marketing-site/images/footer-drop-arrow.svg"
                                                        alt=""
                                                        objectFit="contain"
        
                                                    />
                                                    <span>Planing & Advice</span>
                                                </Link>
                                                <div
                                                    className="snd-sub-menu accordion-items-container"
                                                    data-collapsed="true"
                                                >
                                                    <ul>
                                                        <li className="footer-sub">
                                                            <Link to="#">Blog title goes here</Link>
                                                        </li>
                                                        <li className="footer-sub">
                                                            <Link to="#">Blog title goes here</Link>
                                                        </li>
                                                        <li className="footer-sub">
                                                            <Link to="#">Blog title goes here</Link>
                                                        </li>
                                                        <li className="footer-sub">
                                                            <Link to="#">Blog title goes here</Link>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </li>
                                        </>
                                    )}
								</ul>
							</div>
						</div>
						<div className="footer-contact">
							<div className="contact-details">
								<a href="mailto:hello@edyn.care">
									<EmailIcon className="icon-default"/>
									<p>hello@edyn.care</p>
								</a>
								<a href="tel:020-3970-9900">
									<PhoneIcon className="icon-default"/>
									<p>020-3970-9900</p>
								</a>
							</div>
							<div className="social-links">
								<a href="https://twitter.com/EdynCare">
									<Twitter className="gatsby-image-wrapper"/>
									<p>Twitter</p>
								</a>
								<a href="https://www.facebook.com/EdynCare/">
									<Facebook className="gatsby-image-wrapper"/>
									<p>Facebook</p>
								</a>
							</div>
							<div className="footer-buttons">
								<a href={`${appDomain}/login-redirect`} className="button-link">
									<button className="btn">Login</button>
								</a>
								<Link to='/become-a-carer/' className="button-link">
									<button className="btn">Become a carer</button>
								</Link>
								<Link to="/cta/" className="button-link">
									<button className="btn-primary">Arrange a Call</button>
								</Link>
							</div>
						</div>
						<div className="footer-navigation">
							<div className="footer-nav">
							<ul>
								<li className="footer-nav-title link">
									<Link to="/terms-and-conditions/">Terms and Conditions</Link>
								</li>
								<li className="footer-nav-title link">
									<Link to="/privacy/">Privacy</Link>
								</li>
								{false && (
									<li className="footer-nav-title link">
										<Link to="">Refer a friend</Link>
									</li>
								)}
							</ul>
							</div>
						</div>
					</div>

					{/*   -----------------------------------------------------------------------  */}
					{/*                                 FOOTER IPAD                                */}
					{/*   -----------------------------------------------------------------------  */}

					<div className={`footer-block ipadPro ${!includeCTA ? "no-cta" : ""} ${includeBecomeACarer ? "become-a-carer-margin" : ""}`}>
						<div className="footer-contact">
							<div className="logo">
								<Link to="/">
									<EdynCareWhite />
								</Link>
							</div>
							<div className="contact-details">
								<ul>
									<li>
										<a href="mailto:hello@edyn.care">
											<EmailIcon className="icon-default"/>
											<p>hello@edyn.care</p>
										</a>
									</li>
									<li>
										<a href="tel:020-3970-9900">
											<PhoneIcon className="icon-default"/>
											<p>020-3970-9900</p>
										</a>
									</li>
								</ul>
							</div>
							<div className="social-links">
								<a href="https://twitter.com/EdynCare">
									<Twitter className="gatsby-image-wrapper"/>
								</a>
								<a href="https://www.facebook.com/EdynCare/">
									<Facebook className="gatsby-image-wrapper"/>
								</a>
							</div>
							<div className="footer-buttons">
								<Link to='/become-a-carer/' className="button-link">
									<button className="btn">Become a carer</button>
								</Link>
								<a href={`${appDomain}/login-redirect`} className="button-link">
									<button className="btn">Login</button>
								</a>
								<Link to="/cta/" className="button-link">
									<button className="btn-primary">Arrange a Call</button>
								</Link>
							</div>
						</div>

						<hr className="cargo" />
						<div className="footer-navigation">
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<Link to="/">Home</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/liveincare/">Live-in Care</Link>
									</li>
									{/* <li className="footer-nav-title link">
										<Link to="#">Our Service</Link>
									</li> */}
									<li className="footer-nav-title link">
										<Link to="/approach/">Our Approach</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/pricing/">Our Pricing</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/our-carers/">Our Carers</Link>
									</li>
								</ul>
							</div>
                            {/* {enableBlogContent && (
                                <div className="footer-nav-about">
                                    <ul>
                                        <li className="footer-nav-title">About</li>
                                        <li className="footer-sub">
                                            <Link to="#">Why we started</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Our values</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Our team</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Our advisors</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Recruitment process</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Recruitment events</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="#">Day in the life of an Edyn carer</Link>
                                        </li>
                                    </ul>
                                </div>
                            )} */}
                            {enableConditionsContent && (
                                <div className="footer-nav-conditions">
                                    <ul>
                                        <li className="footer-nav-title">Conditions we care for</li>
										<li className="footer-sub">
											<Link to="/condition/dementia/">Dementia</Link>
										</li>
										<li className="footer-sub">
											<Link to="/condition/parkinsons/">Parkinson's</Link>
										</li>
										<li className="footer-sub">
											<Link to="/condition/cancer/">Cancer</Link>
										</li>
										<li className="footer-sub">
											<Link to="/condition/stroke/">Stroke</Link>
										</li>
										<li className="footer-sub">
											<Link to="/condition/multiple-sclerosis/">Multiple Sclerosis</Link>
										</li>
                                    </ul>
                                </div>
                            )}
                            {enableLocationContent && (
                                <div className="footer-nav-locations">
                                    <ul>
                                        <li className="footer-nav-title">Locations</li>
                                        <li className="footer-sub">
                                            <Link to="/location/london/">London</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="/location/east-midlands/">East Midlands</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="/location/west-of-england/">West Of England</Link>
                                        </li>
                                    </ul>
									<ul>
                                        <li className="footer-nav-title footer-nav__location-li-space"></li>
                                        <li className="footer-sub">
                                            <Link to="/location/south-east/">South East</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="/location/south-west/">South West</Link>
                                        </li>
                                        <li className="footer-sub">
                                            <Link to="/location/west-midlands/">West Midlands</Link>
                                        </li>
                                    </ul>
									
                                </div>
                            )}
							<div className="footer-nav">
								<ul>
									<li className="footer-nav-title link">
										<a target='__blank' href="https://apply.workable.com/edyn-care">Jobs</a>
									</li>
									<li className="footer-nav-title link">
										<Link to="/our-team/">Our Team</Link>
									</li>
									<li className="footer-nav-title link">
										<a href="https://uk.trustpilot.com/review/edyn.care" target="_blank">Customer reviews</a>
									</li>
									<li className="footer-nav-title link">
										<Link to="/terms-and-conditions/">Terms and Conditions</Link>
									</li>
									<li className="footer-nav-title link">
										<Link to="/privacy/">Privacy</Link>
									</li>
								</ul>
							</div>
                            {/* {enableBlogContent && (
                                <div className="footer-nav-column">
                                    <div className="footer-nav-training">
                                        <ul>
                                            <li className="footer-nav-title">Training & Support</li>
                                            <li className="footer-sub">
                                                <Link to="#">Our training process</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="#">Ongoing support</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="#">Refer-a-friend scheme</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="#">Apply now</Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="footer-nav-training">
                                        <ul>
                                            <li className="footer-nav-title">Client stories</li>
                                            <li className="footer-sub">
                                                <Link to="#">Matthew's story</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="#">Iwona &amp; Neil's story</Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>    
                            )} */}
						</div>
                        {enableBlogContent && (
                            <>
                                <hr className="cargo" />
						        <div className="footer-planning">
                                    <h3>Planning &amp; advice</h3>
                                    <div className="planning-navigation">
                                        <ul>
                                            <li className="footer-nav-title">
                                                Starting the conversation
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="/blog/when-is-care-needed/">When is care needed?</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="/blog/what-can-a-live-in-carer-help-with/">What can a live-in carer help with?</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="/blog/live-in-care-vs-care-home/">Live-in care vs a care home</Link>
                                            </li>
                                            <li className="footer-sub">
                                                <Link to="/blog/discussing-care-options-as-a-family/">Discussing care options as a family</Link>
                                            </li>
                                        </ul>
                                        <ul>
                                            <li className="footer-nav-title">Preparing for care</li>
											<li className='footer-sub'>
												<Link to="/blog/how-to-decide-on-the-right-carer-for-you/">How to decide on the right carer for you</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/what-is-a-care-assessment/">What is a care assessment?</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/cqc-vs-introductory-agency/">CQC vs introductory agency</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/what-is-a-care-consultation/">What is a care consultation?</Link>
											</li>
                                        </ul>
                                        <ul>
                                            <li className="footer-nav-title">Paying for care</li>
											<li className='footer-sub'>
												<Link to="/blog/state-and-local-authority-funding/">State and local authority funding</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/self-funding-care/">Self-funding care</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/what-is-the-cost-of-live-in-care/">What is the cost of live-in care?</Link>
											</li>
											<li className='footer-sub'>
												<Link to="/blog/what-are-my-funding-options/">What are my funding options?</Link>
											</li>
                                        </ul>
                                        <ul>
                                            <li className="footer-nav-title">Covid-19</li>
											<li className='footer-sub'>
												<Link to="/blog/our-covid-19-response/">Our Covid-19 response</Link>
											</li>
											<li className='footer-sub'>
												<a href="https://docs.google.com/document/d/1MmMRtmJcfpP6iKJi33pgH91mLLh4wuYUbstUGhqYRik">Covid-19 policy for our clients</a>
											</li>
											<li className='footer-sub'>
												<a href="https://docs.google.com/document/d/194TZvyQ3j7n5lyuCMJzjzN6cltAsaKUkbFkdhfSCKG4">Covid-19 testing policy</a>
											</li>
                                        </ul>
                                    </div>
						        </div>
                            </>
                        )}
					</div>
				</div>
			</div>
		</footer>
	);
}

export default Footer