import {Link} from 'gatsby'
import React, {useState, useEffect} from 'react'

const preserveList = [
    'utm_medium',
    'utm_source',
    'utm_term',
    'utm_campaign',
    'utm_content',
    'gclid'
]

export const LinkWithUTM = ({to, ...linkProps}) => {
    const [enhancedTo, setEnhancedTo] = useState(to)
    useEffect(() => {
        const targetUrl = new URL(to, document.location.origin)
        const currentUrl = new URL(document.location)

        const targetSearchParams = Array.from(targetUrl.searchParams.entries())
        const existingSearchParams = Array.from(currentUrl.searchParams.entries())

        const newSearchParams = targetSearchParams.concat(existingSearchParams.filter(([paramName]) => preserveList.includes(paramName)))

        setEnhancedTo(newSearchParams.length > 0
            ? `${targetUrl.pathname}?${newSearchParams.map(([k, v]) => `${k}=${v}`).join('&')}`
            : targetUrl.pathname)
    }, [])



    return <Link to={enhancedTo} {...linkProps} />
}